<template>
  <div :style="{ height }">
    <announcement-card
      is-centered
      theme="warning">
      <template #title>
        Avertissement
      </template>

      <template #text>
        <icon-ptcy-loader-spinner
          v-if="isPaymentLoading"
          class="text-md mr-1" />

        <!-- paygreen-container needed on paygreen gateway -->
        <!-- used by PaygreenJs to trigger 3DS -->
        <div id="paygreen-container" />
        <div id="paygreen-methods-container" />
        <div id="paygreen-pan-frame" />
        <div id="paygreen-cvv-frame" />
        <div id="paygreen-exp-frame" />
        <div id="paygreen-reuse-checkbox-container" />

        <p
          class="payment-order-action__text"
          v-html="getPaymentError(order)" />

        <p
          class="text-cinnabar"
          v-html="error" />
      </template>

      <template #more-content>
        <potager-button
          :is-loading="isPaymentLoading"
          class="mb-2 last:mb-0"
          full-width
          theme="cinnabar"
          @onClick="retryOrderRequiresAction(order)">
          Réessayer
        </potager-button>

        <potager-button
          full-width
          size="small"
          theme="stroke-cinnabar"
          @onClick="redirectSelectCard(order)">
          Utiliser un autre moyen de paiement
        </potager-button>
      </template>
    </announcement-card>
  </div>
</template>

<script>
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';
import MobileAppMixin from 'Mixins/MobileAppMixin';

import AnnouncementCard from 'Components/announcementCard/AnnouncementCard';
import PotagerButton from 'UI/PotagerButton';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

export default {

  mixins: [
    PaymentMethodsMixin,
    MobileAppMixin,
  ],

  components: {
    AnnouncementCard,
    PotagerButton,
    IconPtcyLoaderSpinner,
  },

  data: () => ({
    height: 'auto',
    error: null,
  }),

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  methods: {
    setHeight() {
      const { height } = this.$refs.wrapper.getBoundingClientRect();
      this.height = `${height}px`;
    },
    resetHeight() {
      this.height = 'auto';
    },
    retryOrderRequiresAction(order) {
      this.error = null;

      this.doOrderRequiresAction(order)
        .catch((err) => {
          const errorCode = `<small>Code d'erreur&nbsp;:&nbsp;${err.code || err}</small>`;
          const SAVLink = `<a href="https://potagercity.force.com/serviceclient/s/contactsupport" class="underline">notre service client</a>`;
          this.error = `Une erreur est survenue lors de la tentative de paiement. Merci de réessayer ou de contacter ${SAVLink}. <br>${errorCode}`;
        })
        .finally(() => {
          if (this.isInAppWebView) {
            this.redirectWebApp();
          }
        });
    },
    redirectSelectCard(order) {
      this.$router.push({
        name: 'mon-compte_orders_detail_select-card',
        params: {
          orderId: order.id,
          cardId: this.getCurrentCardId(order.id),
        },
      });
    },
  },

  mounted() {
    if (this.checkIfNeedUserAction(this.order) && this.$potagerRoute.query.autoRetry) {
      this.$nextTick(() => {
        this.retryOrderRequiresAction(this.order);
      });
    }
  },
};
</script>
