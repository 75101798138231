<template>
  <div class="max-w-6/12 w-full mx-auto">
    <div class="relative">
      <potager-slider
        :align="$mq.bp768 ? 'center': undefined"
        :items="categories"
        :settings="getSliderSettings"
        :slides-per-view="{
          default: 'auto',
          768: 2,
        }"
        name="tunnel-box-slider"
        overflow-visible
        @onInit="ready = true"
        @onSlideChange="onSlideChange">
        <template #default="vProps">
          <card-product-selector
            :category="vProps.item"
            :selected-ref="vProps.item.selectedRef"
            class="bp768:w-72"
            @onSelectRef="setSelectedRef($event, vProps.item.ref)"
            @onSubmit="onSubmit" />
        </template>

        <template #navigation>
          <potager-button
            :class="[
              'tunnel-box-slider__navigation-prev',
              'absolute block top-32 left-0 z-10 transition transform -translate-x-1/2 bp768:translate-x-0 bp768:left-6',
              { 'opacity-0': !ready }]"
            is-circle
            theme="beryl-green">
            <template #icon>
              <icon-arrow-left />
            </template>
          </potager-button>

          <potager-button
            :class="[
              'tunnel-box-slider__navigation-next',
              'absolute block top-32 right-0 z-10 transition transform translate-x-1/2 bp768:translate-x-0 bp768:right-6',
              { 'opacity-0': !ready }]"
            is-circle
            theme="beryl-green">
            <template #icon>
              <icon-arrow-right />
            </template>
          </potager-button>
        </template>
      </potager-slider>
    </div>

    <div
      ref="pagination"
      class="mt-8 flex justify-center" />
  </div>
</template>

<script>
import PotagerSlider from 'UI/PotagerSlider';
import PotagerButton from 'UI/PotagerButton';
import CardProductSelector from 'Components/cards/CardProductSelector';
import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconArrowRight from 'Icons/bold/IconArrowRight';

export default {

  components: {
    PotagerSlider,
    PotagerButton,
    CardProductSelector,
    IconArrowLeft,
    IconArrowRight,
  },

  data: () => ({
    ready: false,
  }),

  props: {
    categories: {
      type: Array,
      required: true,
    },
    initialSlide: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    getSliderSettings() {
      return {
        navigation: {
          nextEl: '.tunnel-box-slider__navigation-next',
          prevEl: '.tunnel-box-slider__navigation-prev',
          disabledClass: 'opacity-0 pointer-events-none',
        },
        pagination: {
          el: this.$refs.pagination,
          bulletClass: 'tunnel-box-slider__pagination-bullet',
          bulletActiveClass: 'tunnel-box-slider__pagination-bullet--active',
          clickable: true,
        },
        initialSlide: this.initialSlide,
        preloadImages: true,
        centerInsufficientSlides: true,
        spaceBetween: 8,
      };
    },
  },

  methods: {
    setSelectedRef(categoryRef, ref) {
      this.$emit('setSelectedRef', categoryRef, ref);
    },
    onSlideChange({ slideIndex }) {
      this.$emit('onSlideChange', slideIndex);
    },
    onSubmit(box) {
      this.$emit('onSubmit', box);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .swiper {
  &-slide {
    @apply opacity-20;
    @apply transition;

    &-active {
      @apply opacity-100;
    }

    &-next {
      @include min-bp768() {
        @apply opacity-100;
      }
    }

    .card-product-selector {
      max-width: 80vw;
    }
  }
}

//bulletClass: 'h-0.75 w-10 bp768:w-8 before:rounded px-1 cursor-pointer before:bg-white-rock before:opacity-50 before:transition before:duration-300 before:ease-in-out before:h-full before-w-full',
//bulletActiveClass: 'before:bg-british-racing',
:deep() .tunnel-box-slider__pagination-bullet {
  @apply p-1;
  @apply cursor-pointer;

  &:before {
    content: '';
    @apply block w-8 h-0.75 rounded;
    @apply bg-white-rock;
    @apply transition;
  }

  &--active {
    &:before {
      @apply bg-british-racing;
    }
  }
}
</style>
