<template>
  <panel
    :back-route="backRoute"
    title="Mes commandes">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        Gérez vos prochaines commandes et consultez vos commandes passées
      </p>
    </template>

    <div class="text-center">
      <order-tabs
        v-if="getHistoricalOrders?.length"
        class="mb-8" />
    </div>

    <transition
      mode="out-in"
      name="fade-fast-translate-y">
      <div
        v-if="$potagerRoute.params.status === 'en-cours'"
        class="panel-section--transparent">
        <div
          v-if="!getOrders?.length && isUserLoaded && !getSubscription?.status"
          class="flex flex-col items-center">
          <icon-illu-basket class="text-[140px] text-british-racing mb-4" />
          <b class="font-semibold">
            Vous n’avez pas de commande pour l’instant.
          </b>
          Mais on peut y remédier&nbsp;:
          <potager-button
            :to="{ name: 'dashboard' }"
            class="mt-4"
            is-rounded
            theme="british-racing">
            Commencer mon marché
          </potager-button>
        </div>

        <orders-time-slots-list v-else />
      </div>

      <div
        v-else
        class="panel-section--transparent">
        <historical-orders-time-slots-list />
      </div>
    </transition>
  </panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_SUBSCRIPTION_TIME_SLOTS_ACTION } from 'Stores/types/userActionsTypes';

import OrderTabs from 'Pages/account/orders/components/AccountOrdersTabs';

import Panel from 'Components/panel/Panel';

import MetaInfosService from 'Classes/services/MetaInfoService';

import IconIlluBasket from 'Icons/graphics/IconIlluBasket';

import PotagerButton from 'UI/PotagerButton';
import OrdersTimeSlotsList from 'Components/timeSlots/OrdersTimeSlotsList';
import HistoricalOrdersTimeSlotsList from 'Components/timeSlots/HistoricalOrdersTimeSlotsList';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    HistoricalOrdersTimeSlotsList,
    OrdersTimeSlotsList,
    PotagerButton,
    Panel,
    OrderTabs,
    IconIlluBasket,
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getOrders',
      'getHistoricalOrders',
      'isUserLoaded',
    ]),
    ...mapGetters('routeHistory', [
      'getLastRoute',
    ]),
    showHistoricalOrders() {
      return !this.isUserLoaded || this.getHistoricalOrders.length > 0;
    },
    metaTitle() {
      return MetaInfosService.generate({
        title: this.$potagerRoute.params.status === 'historique' ? 'Mes commandes passées' : 'Mes commandes en cours',
      });
    },
    backRoute() {
      return this.$potagerRoute.query.context === Context.Basket
        ? { name: 'basket' }
        : { name: 'mon-compte' };
    }
  },

  methods: {
    ...mapActions('user', {
      getSubscriptionTimeSlots: GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
    }),
  },

  created() {
    this.getSubscriptionTimeSlots();
  },

  watch: {
    showHistoricalOrders(val) {
      if (!val) {
        this.$router.push({
          name: 'mon-compte_orders',
          params: { status: 'en-cours' },
        });
      }
    },
  },

  head() {
    return this.metaTitle;
  },
};
</script>
