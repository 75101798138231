import {
  RESET_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_BASKET,
  UPDATE_SUBSCRIPTION_BASKET_TEMP_CUSTOMER_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_BASKET_TEMP_DELIVERY_POINT,
  UPDATE_PRODUCT_SELECTORS,
  UPDATE_PRODUCT_SELECTORS_V2,
  UPDATE_SUBSCRIPTION_BASKET_CARDID,
  UPDATE_SUBSCRIPTION_BASKET_TEMP_GROUP_DELIVERY_DAY,
} from 'Stores/types/subscriptionBasketMutationsTypes';

import {
  UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION,
  UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION,
  CONFIRM_SUBSCRIPTION_BASKET_ACTION,
  PAY_SUBSCRIPTION_BASKET,
  FETCH_PRODUCT_SELECTORS_ACTION,
  FETCH_PRODUCT_SELECTORS_V2_ACTION,
  CLEAR_SUBSCRIPTION_BASKET_ACTION,
} from 'Stores/types/subscriptionBasketActionsTypes';

import { UPDATE_BASKET } from 'Stores/types/basketMutationsTypes';
import { UPDATE_DELIVERY_POINT_SUBSCRIPTION, UPDATE_USER } from 'Stores/types/userMutationsTypes';
import {
  UPDATE_SUBSCRIPTION_BASKET_ACTION,
  UPDATE_USER_ACTION,
} from 'Stores/types/userActionsTypes';

import { getOrderPrice } from 'potagerlogic/dist/Utils/Order/OrderPrices';
import { SPONSORSHIP } from 'Classes/utils/CouponsUtils';
import { SUBSCRIPTION_BASKET_TYPE, SUBSCRIPTION } from 'Classes/Constants';

import { api } from 'Plugins/potagerApiClient';

export default {

  namespaced: true,

  state: {
    address: null,
    coupons: [],
    customerSubscription: null,
    debitDate: null,
    deliveryPoint: null,
    groupDeliveryDay: null,
    priceCategory: SUBSCRIPTION,
    priceSubscription: null,
    priceSubscriptionEnterprise: null,
    priceOneShot: null,
    priceOneShotEnterprise: null,
    productSelectors: [],
    productSelectorsV2: [],
    cardId: null,
    products: [],
    limitDate: null,
  },

  getters: {
    getSubscriptionBasket: (state) => state,
    getAddress: (state) => state.address,
    getCustomerSubscription: (state) => state.customerSubscription,
    getCustomerSubscriptionProduct: (state) => (state.customerSubscription ? state.customerSubscription.product : null),
    getDeliveryPoint: (state) => state.deliveryPoint,
    getGroupDeliveryDay: (state) => state.groupDeliveryDay,
    getPriceCategory: (state) => state.priceCategory,
    getPriceSubscription: (state) => state.priceSubscription,
    getPriceSubscriptionEnterprise: (state) => state.priceSubscriptionEnterprise,
    getRegionId: (state, getters) => (getters.getCustomerSubscriptionProduct ? getters.getCustomerSubscriptionProduct.region.id : state.deliveryPoint?.regionId || 1),
    getCoupons: (state) => state.coupons,
    getPromotedCoupon: (state, getters, rs, rootGetters) => getters
      .getCoupons.map((e) => (e.couponType === SPONSORSHIP ? ({
        ...e,
        ...rootGetters['app/getSponsorshipPromotedText'],
      }) : e))
      .filter((e) => e.promotedTitle && e.promotedDescription)[0],
    getSubTotal: (state, getters) => (getters.getCustomerSubscriptionProduct ? getOrderPrice(state)?.subTotal : 0),
    getTotal: (state, getters) => (getters.getCustomerSubscriptionProduct ? getOrderPrice(state)?.total : 0),
    getProductSelectors: (state) => state.productSelectors,
    getProductSelectorsV2: (state) => state.productSelectorsV2,
    getOrderCardId: (state) => state.cardId,
    getLimitDate: (state) => state.limitDate,
    getDebitDate: (state) => state.debitDate,
    getDeliveryDate: (state) => state.groupDeliveryDay?.timeSlot?.date ?? 0,
  },

  mutations: {
    [UPDATE_SUBSCRIPTION_BASKET_TEMP_CUSTOMER_SUBSCRIPTION](state, payload) {
      state.customerSubscription = {
        product: { ...payload },
      };
      state.priceSubscription = {
        total: payload.price.subscription,
      };
      state.priceSubscriptionEnterprise = {
        total: payload.price.subscriptionEnterprise,
      };
    },
    [UPDATE_SUBSCRIPTION_BASKET_TEMP_DELIVERY_POINT](state, payload) {
      state.deliveryPoint = payload.deliveryPoint;
    },
    [UPDATE_SUBSCRIPTION_BASKET_TEMP_GROUP_DELIVERY_DAY](state, payload) {
      state.groupDeliveryDay = payload.groupDeliveryDay;
    },
    [UPDATE_SUBSCRIPTION_BASKET](state, payload) {
      state.address = payload.address;
      state.coupons = payload.coupons;
      state.customerSubscription = payload.customerSubscription;
      state.groupDeliveryDay = payload.groupDeliveryDay;
      state.deliveryPoint = payload.deliveryPoint;
      state.debitDate = payload.debitDate;
      state.priceSubscription = payload.priceSubscription;
      state.priceSubscriptionEnterprise = payload.priceSubscriptionEnterprise;
      state.priceOneShot = payload.priceOneShot;
      state.priceOneShotEnterprise = payload.priceOneShotEnterprise;
      state.priceCategory = payload.priceCategory;
      state.products = payload.products;
      state.limitDate = payload.limitDate;
    },
    [UPDATE_SUBSCRIPTION_BASKET_CARDID](state, cardId) {
      state.cardId = cardId;
    },
    [RESET_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION](state) {
      state.customerSubscription = null;
    },
    [UPDATE_PRODUCT_SELECTORS](state, payload) {
      state.productSelectors = payload;
    },
    [UPDATE_PRODUCT_SELECTORS_V2](state, payload) {
      state.productSelectorsV2 = payload;
    },
  },

  actions: {
    [UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION]({
      dispatch,
      commit,
      rootGetters
    }, {
      regionId,
      groupDeliveryDayId,
      simulated = false,
      address = null,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION, { root: true });

        if (!rootGetters['session/isLoggedIn']) {
          api.temporaryCustomer.setBasketDeliveryPoint(
            rootGetters['temporaryCustomer/getTemporaryCustomerId'],
            regionId,
            groupDeliveryDayId,
            simulated,
            address,
          )
            .then((resp) => {
              commit(UPDATE_SUBSCRIPTION_BASKET, resp.data.data.subscriptionBasket);
              commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
              resolve(resp);
            })
            .catch((err) => reject(err))
            .finally(() => dispatch('wait/end', UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION, { root: true }));
        } else {
          api.user.setDeliveryPointBasketSubscription(regionId, groupDeliveryDayId, address)
            .then((resp) => {
              commit(`user/${UPDATE_DELIVERY_POINT_SUBSCRIPTION}`, resp.data.data.subscription, { root: true });
              resolve(resp);
            })
            .catch((err) => reject(err))
            .finally(() => dispatch('wait/end', UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION, { root: true }));
        }
      });
    },
    [UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION]({
      commit,
      dispatch,
      getters,
      rootGetters,
    }, {
      regionId,
      product
    }) {
      const {
        productReference,
        id
      } = product;

      const loading = {
        start: () => {
          dispatch('wait/start', UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION, { root: true });
          dispatch('wait/start', `${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}_${id}`, { root: true });
        },
        end: () => {
          dispatch('wait/end', UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION, { root: true });
          dispatch('wait/end', `${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}_${id}`, { root: true });
        },
      };

      return new Promise((resolve, reject) => {
        loading.start();

        if (!rootGetters['session/isLoggedIn']) {
          // Si on a pas encore de point de livraison on set juste le produit en temporaire.
          if (!getters.getDeliveryPoint) {
            commit(UPDATE_SUBSCRIPTION_BASKET_TEMP_CUSTOMER_SUBSCRIPTION, product);
            loading.end();
            resolve();
            return;
          }

          api.temporaryCustomer.addBasketSubscription(
            rootGetters['temporaryCustomer/getTemporaryCustomerId'],
            regionId,
            productReference,
            SUBSCRIPTION_BASKET_TYPE,
          )
            .then((resp) => {
              commit(UPDATE_SUBSCRIPTION_BASKET, resp.data.data.subscriptionBasket);
              resolve(resp);
            })
            .catch((err) => {
              commit(RESET_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION);
              reject(err);
            })
            .finally(() => {
              loading.end();
            });
        } else {
          this.dispatch(`user/${UPDATE_SUBSCRIPTION_BASKET_ACTION}`, {
            idRegion: regionId,
            idFormule: id
          }, { root: true })
            .then((resp) => {
              commit(UPDATE_SUBSCRIPTION_BASKET, resp.data.data.subscriptionBasket);
              resolve(resp);
            })
            .catch((err) => reject(err))
            .finally(() => {
              loading.end();
            });
        }
      });
    },
    [CONFIRM_SUBSCRIPTION_BASKET_ACTION]({
      dispatch,
      getters
    }, {
      regionId,
      groupDeliveryDayId,
      address
    }) {
      const customerSubscription = getters.getCustomerSubscription;

      return new Promise((resolve, reject) => {
        dispatch('wait/start', CONFIRM_SUBSCRIPTION_BASKET_ACTION, { root: true });
        dispatch(UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION, {
          regionId,
          groupDeliveryDayId,
          address
        })
          .then((resp) => {
            if (!customerSubscription) {
              dispatch('wait/end', CONFIRM_SUBSCRIPTION_BASKET_ACTION, { root: true });
              resolve(resp);
            } else {
              const { product } = customerSubscription;
              dispatch(UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION, {
                regionId,
                product,
                address
              })
                .then((response) => resolve(response))
                .catch((err) => reject(err))
                .finally(() => dispatch('wait/end', CONFIRM_SUBSCRIPTION_BASKET_ACTION, { root: true }));
            }
          })
          .catch((err) => {
            reject(err);
            dispatch('wait/end', CONFIRM_SUBSCRIPTION_BASKET_ACTION, { root: true });
          });
      });
    },
    [PAY_SUBSCRIPTION_BASKET]({
      commit,
      dispatch
    }, cardId) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', PAY_SUBSCRIPTION_BASKET, { root: true });
        api.payment.paySubscriptionBasket(cardId, true)
          .then(({ data }) => {
            if (data.success) {
              dispatch(`user/${UPDATE_USER_ACTION}`, null, { root: true })
                .then(() => resolve(data))
                .catch((error) => reject(error))
                .finally(() => {
                  dispatch('wait/end', PAY_SUBSCRIPTION_BASKET, { root: true });
                  commit(UPDATE_SUBSCRIPTION_BASKET_CARDID, cardId);
                });
            } else {
              console.error(response.errors || response);
              reject(response.errors || response);
              dispatch('wait/end', PAY_SUBSCRIPTION_BASKET, { root: true });
            }
          })
          .catch((error) => {
            console.error(error?.globals[0].message || error);
            dispatch('wait/end', PAY_SUBSCRIPTION_BASKET, { root: true });
            reject(error);
          });
      });
    },
    [FETCH_PRODUCT_SELECTORS_ACTION]({
      commit,
      dispatch
    }, {
      regionId,
      reset
    }) {
      dispatch('wait/start', FETCH_PRODUCT_SELECTORS_ACTION, { root: true });
      return new Promise((resolve) => {
        if (reset) commit(UPDATE_PRODUCT_SELECTORS, null);
        api.formule.findAllSelectors(regionId)
          .then((resp) => {
            commit(UPDATE_PRODUCT_SELECTORS, resp.data);
            resolve(resp);
          })
          .finally(() => dispatch('wait/end', FETCH_PRODUCT_SELECTORS_ACTION, { root: true }));
      });
    },
    [FETCH_PRODUCT_SELECTORS_V2_ACTION]({
      commit,
      dispatch,
      rootGetters
    }, { regionId }) {
      dispatch('wait/start', FETCH_PRODUCT_SELECTORS_V2_ACTION, { root: true });
      return new Promise((resolve) => {
        api.formule.findAllSelectorsV2(regionId || rootGetters['session/getRegionId'])
          .then((resp) => {
            commit(UPDATE_PRODUCT_SELECTORS_V2, resp.data);
            resolve(resp);
          })
          .finally(() => dispatch('wait/end', FETCH_PRODUCT_SELECTORS_V2_ACTION, { root: true }));
      });
    },
    [CLEAR_SUBSCRIPTION_BASKET_ACTION]({
      dispatch,
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', CLEAR_SUBSCRIPTION_BASKET_ACTION, { root: true });

        if (rootGetters['session/isLoggedIn']) {
          api.user.clearSubscriptionBasket()
            .then((response) => {
              const user = response.data.data;
              commit(`user/${UPDATE_USER}`, user, { root: true });
              commit(UPDATE_SUBSCRIPTION_BASKET, user.subscriptionBasket);
              resolve(response);
            })
            .catch((err) => reject(err))
            .finally(() => dispatch('wait/end', CLEAR_SUBSCRIPTION_BASKET_ACTION, { root: true }));
        } else {
          dispatch('wait/end', CLEAR_SUBSCRIPTION_BASKET_ACTION, { root: true });
          reject();
        }
      });
    },
  },
};
