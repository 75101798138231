<template>
  <no-subscription-detail-page v-if="!isSubscriptionActive" />

  <subscription-error-page v-else-if="!getNextGenerationDate && !getSubscription" />

  <flying-page
    v-else
    preview>
    <div class="subscription-detail">
      <potager-picture
        :src="getSubscription.product.image"
        :title="getSubscription.product.name"
        background
        class="subscription-detail__poster">
        <ul class="subscription-detail__tags">
          <potager-badge
            tag="li"
            theme="go-green">
            Abonnement
          </potager-badge>
        </ul>

        <div
          v-if="isSubscriptionSuspended"
          class="subscription-detail__poster__message">
          Votre abonnement est suspendu
        </div>

        <div
          v-else-if="isOrderCanceledStatus(getSubscriptionOrder)"
          class="subscription-detail__poster__message">
          Vous avez annulé la commande contenant votre panier en abonnement
        </div>

        <div
          v-else-if="!getSubscriptionOrder?.containsSubscriptionBox && getSubscriptionOrder?.isEditable"
          class="subscription-detail__poster__message">
          Vous avez remplacé votre panier en abonnement
        </div>

        <div
          v-else-if="getSubscription?.temporaryOrder || isSubscriptionOrderNotYetCreated"
          class="subscription-detail__poster__message">
          Rendez-vous jeudi pour découvrir la compositon de la semaine prochaine
        </div>
      </potager-picture>

      <div class="subscription-detail__content">
        <section class="subscription-detail__section">
          <p v-if="!getSubscription?.temporaryOrder && !isSubscriptionOrderNotYetCreated">
            {{ isNextWeek(getNextSubscriptionOrderDeliveryDate) ? 'La semaine prochaine' : 'Cette semaine' }}
            dans
            votre panier en
            abonnement
          </p>

          <p class="subscription-detail__title">
            {{ getSubscription.product.name }}
          </p>

          <p
            class="subscription-detail__baseline"
            v-html="getWeightAndBoxPeopleQuantity(getSubscription?.product)" />

          <p
            v-if="!getSubscription?.temporaryOrder && !isSubscriptionOrderNotYetCreated"
            class="subscription-detail__description">
            {{ getSubscription.product.descriptionOfWeek }}
          </p>

          <p
            v-else
            class="subscription-detail__description">
            <template v-if="isSubscriptionSuspended">
              Votre abonnement est actuallement suspendu. Votre prochaine commande est prévue pour le
              <b>{{ tmsToFormat(getNextSubscriptionOrderDeliveryDate, 'iii dd MMMM') }}</b>.
            </template>

            <template v-else>
              Le contenu de votre prochain panier sera dévoilé <b>
                {{
                  tmsToFormat(getNextThursday(), 'iiii dd MMMM')
                }}
              </b>.
              Vous recevrez un e-mail pour vous prévenir.
            </template>
          </p>
        </section>

        <section
          v-if="!getSubscription?.temporaryOrder && !isSubscriptionOrderNotYetCreated"
          :id="calcSlug(getSubscription.product.name)"
          class="subscription-detail__section">
          <h2 class="subscription-detail__subtitle">
            Quoi de bon dans ce panier ?
          </h2>

          <product-list
            :products="getSubscription.product.products"
            :show-mention-category="getSubscription.product.categoryMentionCategory"
            :show-mention-weight="getSubscription.product.categoryMentionWeight"
            class="subscription-detail__product-list"
            flying-page />
        </section>

        <upselling-slider flying />

        <associated-content-slider
          v-if="!getSubscription?.temporaryOrder && !isSubscriptionOrderNotYetCreated"
          :items="getSubscription.product.recipes"
          subtitle="Avec ce panier, vous pourriez préparer..."
          title="Nos idées recettes" />

        <associated-content-slider
          v-if="!getSubscription?.temporaryOrder && !isSubscriptionOrderNotYetCreated"
          :items="getSubscription.product.suppliers"
          subtitle="Qui fournissent certains produits de ce panier"
          title="Faites la connaissance de nos producteurs" />

        <order-summary
          :order="getSubscriptionOrder"
          baseline="Elle est liée à votre abonnement et a été créée automatiquement." />
      </div>
    </div>

    <template #footer>
      <subscription-detail-page-footer />
    </template>
  </flying-page>
</template>

<script>
import { mapGetters } from 'vuex';

import FlyingPageMixin from 'Mixins/FlyingPageMixin';
import SubscriptionOrderMixin from 'Mixins/SubscriptionOrderMixin';

import { getWeightAndBoxPeopleQuantity } from 'potagerlogic/dist/Utils/Box/BoxQuantity';
import { calcSlug } from 'Classes/utils/RouteUtils';
import { getNextThursday } from 'potagerlogic/dist/Utils/Dates/DateCalculation';
import { isNextWeek } from 'potagerlogic/dist/Utils/Dates/DateCheck';
import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import PotagerBadge from 'UI/PotagerBadge';
import PotagerPicture from 'UI/PotagerPicture';

import ProductList from 'Components/lists/ProductList';
import OrderSummary from 'Components/order/OrderSummary';

import NoSubscriptionDetailPage from './NoSubscriptionDetailPage';
import SubscriptionErrorPage from './SubscriptionErrorPage';
import SubscriptionDetailPageFooter from './SubscriptionDetailPageFooter';

import { isOrderCanceledStatus } from 'potagerlogic/dist/Utils/Order/OrderStatus';

export default {
  name: 'SubscriptionDetailPage',

  mixins: [
    FlyingPageMixin,
    SubscriptionOrderMixin,
  ],

  components: {
    NoSubscriptionDetailPage,
    SubscriptionErrorPage,
    SubscriptionDetailPageFooter,
    ProductList,
    PotagerBadge,
    OrderSummary,
    PotagerPicture,
  },

  computed: {
    ...mapGetters('basket', [
      'getLinkedOrderProducts',
      'getProducts',
      'canContainMiniBox',
    ]),
    ...mapGetters('dashboard', [
      'getMiniBoxes',
    ]),
    ...mapGetters('user', [
      'getSubscription',
      'getSubscriptionOrder',
      'isSubscriptionActive',
      'getNextGenerationDate',
      'isSubscriptionSuspended',
    ]),
  },

  methods: {
    getNextThursday,
    tmsToFormat,
    isNextWeek,
    isOrderCanceledStatus,
    getWeightAndBoxPeopleQuantity,
    calcSlug,
  },
};
</script>
