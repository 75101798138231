<template>
  <div class="bg-white rounded-lg mb-2.5 block last:mb-0 relative text-left p-3 font-normal">
    <potager-button
      v-if="closable"
      :is-disabled="disabled"
      class="absolute right-1 top-1"
      is-circle
      prevent
      size="small"
      theme="transparent"
      @onClick="$emit('onClose')">
      <template #icon>
        <icon-close class="text-xs" />
      </template>
    </potager-button>

    <div
      class="
        flex items-center pb-4 last:pb-0 mb-4 last:mb-0
        border-0 border-b border-solid border-porcelain last:border-b-0
      ">
      <div
        :class="[
          'rounded-full w-8 h-8 flex items-center justify-center text-xs.5 font-bold',
          getTheme,
        ]">
        <icon-shop />
      </div>

      <div class="ml-2.5 flex-1 self-center">
        <p class="text-sm font-semibold">
          {{ deliveryDisplayedName }}
        </p>

        <p
          v-if="deliveryDisplayedAddress"
          class="text-xs">
          <span
            v-if="getDistanceFromUser"
            class="font-semibold">
            {{ getDistanceFromUser }} -
          </span>

          <span>
            {{ deliveryDisplayedAddress }}
          </span>
        </p>

        <p
          v-if="deliveryPoint.isWelco"
          :class="{ 'text-xs.5 italic': true, 'mt-3': !!deliveryDisplayedAddress }">
          En partenariat avec Welco, solution de récupération de colis entre voisins.
        </p>
      </div>
    </div>

    <div class="flex justify-between">
      <slot name="gdds-items">
        <div
          v-if="showGdds"
          class="font-xs leading-[18px]">
          Jour(s) de livraison&nbsp;:
          <div>
            <span
              v-for="(day, index) in getGroupDeliveryDays"
              :key="index"
              class="font-semibold">
              {{ day }}<template v-if="index < getGroupDeliveryDays.length - 1">, </template>
            </span>
          </div>
        </div>
      </slot>

      <potager-button
        size="small"
        theme="go-green"
        @onClick="$emit('onClick', deliveryPoint)">
        Choisir
      </potager-button>
    </div>

    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import PotagerButton from 'UI/PotagerButton';

import IconClose from 'Icons/regular/IconClose';
import IconShop from 'Icons/bold/IconShop';

export default {
  components: {
    PotagerButton,
    IconClose,
    IconShop,
  },

  props: {
    deliveryPoint: {
      type: Object,
      required: true,
    },
    context: {
      type: String,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
    showGdds: {
      type: Boolean,
      required: false,
      default: false,
    },
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getUserPosition',
      'getMapPublicSearchResult',
    ]),
    ...mapGetters('session', [
      'getAddress',
    ]),
    ...mapGetters('homeDelivery', {
      getEditingAddress: 'getAddress',
    }),
    contextualAddress() {
      return this.getEditingAddress || this.getAddress(this.context);
    },
    isHomeDelivery() {
      return this.deliveryPoint.isHomeDelivery;
    },
    deliveryDisplayedName() {
      const {
        isHomeDelivery,
        groupName
      } = this.deliveryPoint;
      return isHomeDelivery ? 'Livraison à domicile' : groupName;
    },
    deliveryDisplayedAddress() {
      const {
        isHomeDelivery,
        groupDeliveryAddress
      } = this.deliveryPoint;

      if (isHomeDelivery) {
        if (this.contextualAddress) {
          return `${this.contextualAddress.streetNumber || ''} ${this.contextualAddress.streetName} - ${this.contextualAddress.postalCode} ${this.contextualAddress.city}`;
        }
        return 'Veuillez compléter votre adresse';
      }

      return groupDeliveryAddress;
    },
    getDistanceFromUser() {
      const distance = this.deliveryPoint.woosDlp?.properties?.distance;
      if (distance) {
        return distance > 1000
          ? `${(distance / 1000).toFixed(distance > 10000 ? 0 : 2)
            .replace('.', ',')}km`
          : `${parseInt(distance, 10)}m`;
      }
      return null;
    },
    getGroupDeliveryDays() {
      return (this.deliveryPoint?.groupDeliveryDay || [])
        .map((gdd) => {
          return tmsToFormat(gdd.currentWeekTheoricalTimeSlot.date, 'EEEE');
        });
    },
    getTheme() {
      const { isSelected } = this;

      if (!this.deliveryPoint?.type === 'gmap') {
        if (this.deliveryPoint?.isDisabled && this.deliveryPoint?.isDisabledForSubscription) {
          return 'bg-warm-grey text-white';
        }
        if (isSelected) return 'bg-british-racing text-white';
      }
      return 'bg-beryl-green text-charcoal';
    },
  },
};
</script>
