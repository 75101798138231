<template>
  <status-banner
    v-if="getBasketMessage"
    :to="getBasketMessage.redirect ? {
      ...getBasketMessage.redirect,
      query: {
        context,
      }
    } : undefined"
    :type="getBasketMessage.type">
    <span v-html="getBasketMessage.text" />
  </status-banner>
</template>

<script>
import { mapGetters } from 'vuex';

import ModalUnusualDeliveryPoint from 'Modals/ModalUnusualDeliveryPoint';

import StatusBanner from 'Components/statusBanner/StatusBanner';

import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    StatusBanner,
  },

  data() {
    return {
      context: Context.Basket,
    };
  },

  computed: {
    ...mapGetters('basket', [
      'getBasketMessage',
    ]),
  },

  methods: {
    openModal() {
      this.$modal.open(ModalUnusualDeliveryPoint);
    },
  },
};
</script>
