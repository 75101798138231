<template>
  <div
    v-if="isSubscriptionActive || !isUserLoaded"
    :class="[
      'subscriber-bar',
      'subscriber-bar--is-sub',
      'pb-8 bp768:pb-12 bp640:pb-4',
      'bp768:-mb-2 bp640:mb-0',
    ]">
    <template v-if="!isUserLoaded">
      <div class="subscriber-bar__title h-5 w-1/3 skeleton" />

      <div class="subscriber-bar__subtitle h-5 w-1/4 skeleton" />

      <div class="subscriber-bar__row flex bp1024:flex-col gap-4">
        <div
          v-for="i in [1, 2, 3]"
          :key="`skeleton-${i}`"
          class="skeleton h-24 flex-1" />
      </div>
    </template>

    <template v-else>
      <subscriber-bar-delivery-note />

      <h2 class="subscriber-bar__title mb-4">
        {{ isNextWeek(getNextSubscriptionOrderDeliveryDate) ? 'La semaine prochaine' : 'Cette semaine' }}

        <potager-badge
          :redirect="routes.mySubscription"
          :theme="isSubscriptionSuspended ? 'sandy-beach' : 'beryl-green'"
          class="subscriber-bar__title__badge"
          size="small">
          {{ isSubscriptionSuspended ? 'Abonnement suspendu' : 'Abonnement actif' }}
        </potager-badge>
      </h2>

      <subscriber-bar-items :routes="routes" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerBadge from 'UI/PotagerBadge';

import SubscriberBarItems from 'Components/subscriberBar/SubscriberBarItems';

import COLORS from 'potagerlogic/dist/Enums/Colors';
import { isNextWeek } from 'potagerlogic/dist/Utils/Dates/DateCheck';
import SubscriberBarDeliveryNote from 'Components/subscriberBar/SubscriberBarDeliveryNote.vue';

import SubscriptionOrderMixin from 'Mixins/SubscriptionOrderMixin';

const { bianca } = COLORS;

export default {
  mixins: [SubscriptionOrderMixin],

  components: {
    SubscriberBarDeliveryNote,
    SubscriberBarItems,
    PotagerBadge,
  },

  data: () => ({
    colors: { bianca },
  }),

  computed: {
    ...mapGetters('user', [
      'getSubscriptionOrder',
      'isUserLoaded',
      'isSubscriptionActive',
      'isSubscriptionSuspended',
    ]),
    routes() {
      const routes = {
        subscriptionPage: { name: 'detail-user-subscription-item' },
        mySubscription: { name: 'mon-compte_subscription' },
        mySubscriptionSuspended: { name: 'mon-compte_orders' },
      };

      if (this.getSubscriptionOrder?.id) {
        routes.mySubscriptionOrder = {
          name: 'mon-compte_orders_detail',
          params: {
            orderId: this.getSubscriptionOrder.id,
          },
        };
      }

      return routes;
    },
  },

  methods: { isNextWeek },
};
</script>
