<template>
  <time-slots-list
    v-if="isActiveOrdersLoading || getOrders?.length"
    :skeleton="isActiveOrdersLoading"
    :skeleton-count="1"
    :time-slots="getOrders" />

  <div
    v-if="isActiveOrdersLoading || getOrders?.length"
    :class="['panel-section__separator my-8', { 'animate-pulse': isSubscriptionTimeSlotsLoading }]" />

  <time-slots-list
    :skeleton="isSubscriptionTimeSlotsLoading"
    :skeleton-count="3"
    :time-slots="subscriptionTimeSlots" />
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_SUBSCRIPTION_TIME_SLOTS_ACTION } from 'Stores/types/userActionsTypes';

import TimeSlotsList from 'Components/timeSlots/TimeSlotsList';

export default {
  name: 'OrdersTimeSlotsList',
  components: { TimeSlotsList },
  data() {
    return {
      GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
    };
  },
  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getOrders',
      'subscriptionTimeSlots',
      'isUserLoaded',
    ]),
    isActiveOrdersLoading() {
      return !this.getOrders?.length && !this.isUserLoaded;
    },
    isSubscriptionTimeSlotsLoading() {
      return !this.subscriptionTimeSlots?.length &&
        (this.$wait.is(GET_SUBSCRIPTION_TIME_SLOTS_ACTION) || !this.isUserLoaded);
    },
  },
};
</script>
