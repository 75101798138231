<template>
  <div
    :class="[
      'dlp-resume',
      condensed ? 'dlp-resume--condensed' : 'panel-section'
    ]">
    <template v-if="condensed">
      <potager-button
        :class="[
          'dlp-resume__delivery-point',
          {
            'dlp-resume__delivery-point--selected rounded-r-none pr-0 max-w-[66%] bp375:max-w-[55%]': computedDeliveryPoint,
          },
        ]"
        :readonly="!deliveryPointRedirect || readonly"
        :theme="computedTheme"
        :to="deliveryPointRedirect"
        is-rounded>
        <template #icon>
          <icon-home v-if="computedDeliveryPoint?.isHomeDelivery" />
          <icon-two-pin-marker v-else />
        </template>

        {{ deliveryDisplayedName }}
      </potager-button>

      <potager-button
        v-if="computedDeliveryPoint"
        :class="[
          'rounded-l-none',
          {
            'text-cinnabar': state === MessageType.Error,
            // 'text-mango-tango': state === MessageType.Warning,
          },
        ]"
        :readonly="!deliveryPointRedirect || readonly"
        :theme="computedTheme"
        :to="deliveryDateRedirect"
        :tooltip="!computedGDD?.timeSlot ? 'Votre précédent jour de retrait n\'est plus disponible' : undefined"
        is-rounded>
        <template #icon>
          <icon-calendar />
        </template>

        <span
          class="text-xs.5"
          v-html="capitalize(deliveryDate)" />
      </potager-button>
    </template>

    <template v-else>
      <slot />

      <panel-section-navigator
        :to="!readonly ? deliveryPointRedirect : undefined"
        class="text-xs.5">
        <template #left>
          <component :is="isHomeDelivery ? 'icon-home' : 'icon-two-pin-marker'" />
        </template>

        <span>{{ deliveryDisplayedName }}</span><span
          v-if="deliveryDisplayedAddress"
          class="line-clamp-1">
          {{ deliveryDisplayedAddress }}
        </span>
      </panel-section-navigator>

      <panel-section-navigator
        v-if="computedDeliveryPoint"
        :to="!readonly ? deliveryDateRedirect : undefined">
        <template #left>
          <icon-calendar />
        </template>

        <span
          class="text-xs.5"
          v-html="capitalize(deliveryDate)" />
      </panel-section-navigator>

      <time-slot-note
        v-if="!condensed && !SubscriptionContexts.includes(this.context)"
        :time-slot="order?.timeSlot || computedGDD?.timeSlot"
        inline />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { capitalize } from 'potagerlogic/dist/Formatting';
import { getDlpDetailsRoute, getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';
import {
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE,
  DELIVERY_PUBLIC_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';
import { getGddFormattedDay } from 'potagerlogic/dist/Utils/DeliveryPoint/GroupDeliveryDay';
import { MessageType } from 'potagerlogic/dist/Enums/Messages';
import { Context, SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';
import { formatTimeSlot } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import PotagerInfo from 'UI/PotagerInfo';
import PotagerButton from 'UI/PotagerButton';
import PotagerTooltip from 'UI/PotagerTooltip';

import CollapsibleEllipsis from 'Components/collapsible/CollapsibleEllipsis';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';
import TimeSlotNote from 'Components/timeSlots/TimeSlotNote';

import IconInformationCircle from 'Icons/bold/IconInformationCircle';
import IconCalendar from 'Icons/bold/IconCalendar';
import IconHome from 'Icons/bold/IconHome';
import IconTwoPinMarker from 'Icons/bold/IconTwoPinMarker';

export default {
  components: {
    TimeSlotNote,
    PotagerTooltip,
    CollapsibleEllipsis,
    IconCalendar,
    IconHome,
    IconInformationCircle,
    IconTwoPinMarker,
    PotagerButton,
    PotagerInfo,
    PanelSectionNavigator,
  },

  props: {
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
    context: {
      type: String,
      required: false,
      default: Context.User,
    },
    deliveryPoint: {
      type: Object,
      default: undefined,
    },
    groupDeliveryDay: {
      type: Object,
      required: false,
      default: undefined,
    },
    order: {
      type: Object,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: undefined,
    },
    contrasted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      MessageType,
      SubscriptionContexts,
    };
  },

  computed: {
    ...mapGetters('session', [
      'getAddress',
    ]),
    ...mapGetters('basket', [
      'getBasketStateTypeByContext',
      'getBasketMessage',
      'getDeliveryPoint',
      'getSelectedGdd',
    ]),
    ...mapGetters('user', [
      'getSubscriptionDeliveryPoint',
      'getSubscriptionGroupDeliveryDay',
    ]),
    state() {
      return [Context.Basket, Context.User]
        .includes(this.context)
        ? this.getBasketStateTypeByContext(this.context)
        : undefined;
    },
    computedTheme() {
      if (this.condensed) {
        return this.computedDeliveryPoint ? (this.contrasted ? 'white' : 'bianca') : 'go-green';
      } else {
        return 'white';
      }
    },
    deliveryPointRedirect() {
      return getDlpSearchRoute({
        context: this.context,
        orderId: this.order?.id,
        currentRoute: this.$potagerRoute
      });
    },
    type() {
      if (!this.computedDeliveryPoint) return undefined;

      const {
        isHomeDelivery,
        private: isPrivate,
      } = this.computedDeliveryPoint;

      if (isHomeDelivery) {
        return DELIVERY_HOME_TYPE;
      }
      if (isPrivate) {
        return DELIVERY_PRIVATE_TYPE;
      }
      return DELIVERY_PUBLIC_TYPE;
    },
    deliveryDateRedirect() {
      return getDlpDetailsRoute({
        context: this.context,
        type: this.type,
        deliveryPoint: this.computedDeliveryPoint,
        orderId: this.order?.id,
        query: {
          onlyGDD: true,
        },
        currentRoute: this.$potagerRoute,
      });
    },
    deliveryDate() {
      if (this.order?.timeSlot) {
        return formatTimeSlot(this.order.timeSlot, {
          condensed: this.condensed,
          showHours: !this.condensed
        });
      }

      return getGddFormattedDay(this.computedGDD, {
        context: this.context,
        condensed: this.condensed || SubscriptionContexts.includes(this.context),
      });
    },
    isHomeDelivery() {
      return this.computedDeliveryPoint?.isHomeDelivery;
    },
    homeDeliveryAddress() {
      return this.getAddress(this.context);
    },
    deliveryDisplayedName() {
      if (!this.computedDeliveryPoint) return 'Choisir un point de livraison';
      const {
        isHomeDelivery,
        groupName
      } = this.computedDeliveryPoint;
      return isHomeDelivery ? 'Livraison à domicile' : groupName;
    },
    deliveryDisplayedAddress() {
      if (!this.computedDeliveryPoint || SubscriptionContexts.includes(this.context)) return undefined;

      const {
        isHomeDelivery,
        groupDeliveryAddress
      } = this.computedDeliveryPoint;

      if (isHomeDelivery) {
        if (this.homeDeliveryAddress) {
          return `${this.homeDeliveryAddress.streetNumber || ''} ${this.homeDeliveryAddress.streetName} - ${this.homeDeliveryAddress.postalCode} ${this.homeDeliveryAddress.city}`;
        }
        return 'Veuillez compléter votre adresse';
      }

      return groupDeliveryAddress;
    },
    computedDeliveryPoint() {
      const deliveryPoint = SubscriptionContexts.includes(this.context) ? this.getSubscriptionDeliveryPoint : this.getDeliveryPoint;
      return this.deliveryPoint || this.order?.deliveryPoint || deliveryPoint;
    },
    computedGDD() {
      if (this.groupDeliveryDay) return this.groupDeliveryDay;
      return SubscriptionContexts.includes(this.context)
        ? this.getSubscriptionGroupDeliveryDay
        : this.getSelectedGdd;
    },
  },

  methods: {
    capitalize,
  },
};
</script>

<style lang="scss">
.dlp-resume {
  &--condensed {
    @apply flex items-center mx-auto justify-center;
    @apply whitespace-nowrap;
    max-width: min(100%, 360px);

    > * {
      flex: 0;

      .potager-button__text {
        @apply text-xs.5;
      }

      &.dlp-resume__delivery-point--selected {
        @apply w-full;

        .potager-button__text {
          @apply pr-2 border-r border-white-rock;
        }

        + * {
          @apply pl-2;
        }
      }
    }
  }
}
</style>
