<template>
  <template v-if="deliveryPoint?.groupDeliveryDay?.length">
    <div
      v-for="(gdd) in [...deliveryPoint?.groupDeliveryDay]?.sort((a, b) => a.timeSlot.date - b.timeSlot.date)"
      :key="`gdd-${gdd.id}`"
      class="panel-section text-left mb-3">
      <div class="panel-section__line flex justify-between">
        <div class="leading-normal font-semibold flex flex-col gap-1">
          <p
            :class="[
              'text-sm',
              {
                'text-cinnabar': isGddDisabled(gdd, { context }),
              },
            ]"
            v-html="getGddTitle(gdd)" />

          <span
            class="font-normal text-xs"
            v-html="getGddContent(gdd)" />
        </div>

        <potager-button
          :is-disabled="isGddDisabled(gdd, { context })"
          :is-loading="isLoading"
          :theme="isGddDisabled(gdd, { context }) ? 'charcoal' : 'go-green'"
          :tooltip="getGddStatus(gdd,{ context })"
          class="self-end font-bold text-xs.5"
          is-rounded
          size="small"
          @onClick="onSubmit(gdd)">
          Choisir
        </potager-button>
      </div>

      <time-slot-note
        v-if="!SubscriptionContexts.includes(context)"
        :time-slot="gdd.timeSlot"
        inline />
    </div>
  </template>

  <div
    v-else
    class="panel-section">
    <div class="panel-section__line bg-rossabel text-cinnabar font-semibold">
      <span class="font-normal">⚠️</span>

      <template v-if="isInAnotherRegion">
        Vous ne pouvez pas choisir ce point car il est en dehors de votre région.<br><br>
        <potager-link
          :to="{ name: 'contact' }"
          class="text-current underline"
          target="_blank">
          Contactez le service client<br>
        </potager-link>
        si vous souhaitez changer de région.
      </template>

      <template v-else>
        Désolé, ce point de retrait est actuellement indisponible pour une durée indéterminée.<br>Veuillez
        sélectionner un autre point de retrait parmi ceux disponibles.

        <div class="text-center">
          <potager-button
            :to="dlpSearchRoute"
            class="mt-4"
            is-rounded>
            Choisir un autre point relais
          </potager-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  getGddFormattedDay,
  getGDDNoteTitle,
  getGddNote,
  getGddStatus,
  isGddDisabled,
} from 'potagerlogic/dist/Utils/DeliveryPoint/GroupDeliveryDay';
import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';
import WORDINGS from 'potagerlogic/dist/Constants/Wordings';
import { SubscriptionContexts } from 'potagerlogic/dist/Enums/Context';
import { capitalize } from 'potagerlogic/dist/Formatting';

import PotagerButton from 'UI/PotagerButton';

import ModalList from 'Modals/ModalList';

import { UPDATE_DELIVERY_POINT_LOADERS } from 'Classes/Loaders';
import { getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';

import TimeSlotNote from 'Components/timeSlots/TimeSlotNote.vue';

export default {
  components: {
    TimeSlotNote,
    PotagerButton,
  },

  props: {
    context: {
      type: String,
      default: undefined,
    },
    deliveryPoint: {
      type: Object,
      required: true,
    },
  },

  emits: ['onSubmit'],

  data() {
    return {
      WORDINGS,
      SubscriptionContexts,
    };
  },

  computed: {
    ...mapGetters('tunnel', [
      'getPrevStepRoute',
      'getStepRouteByName',
    ]),
    ...mapGetters('map', [
      'getUserSearch',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]),
    ...mapGetters('user', [
      'getEditableOrders',
    ]),
    isInAnotherRegion() {
      return this.isLoggedIn && this.deliveryPoint?.regionId !== this.getRegionId;
    },
    isLoading() {
      return this.$wait.is(UPDATE_DELIVERY_POINT_LOADERS);
    },
    dlpSearchRoute() {
      return getDlpSearchRoute();
    }
  },

  methods: {
    getGddNote,
    getGddStatus,
    isGddDisabled,
    getGddTitle(gdd) {
      return capitalize(getGddFormattedDay(gdd, {
        context: this.context,
        showTheoricalDate: !!gdd?.timeSlot?.deliveryNote
      }));
    },
    getGddContent(gdd) {
      if (this.isGddDisabled(gdd, { context: this.context })) return 'Les livraisons ce jour ne seront exceptionnellement pas assurées.';

      const from = tmsToFormat(gdd.timeSlot.startHour, 'HH\'h\'mm');
      const to = tmsToFormat(gdd.timeSlot.endHour, 'HH\'h\'mm');
      return this.deliveryPoint?.isHomeDelivery
        ? `Livraison <b class="font-semibold">entre ${from} et ${to}</b>.`
        : `Commande déposée <b class="font-semibold">avant ${from}</b></br> et disponible <b class="font-semibold">jusqu’à ${to}</b>.`;
    },
    onSubmit(gdd) {
      const note = getGddNote(gdd, { deliveryPoint: this.deliveryPoint });
      if (note) {
        this.$modal.open(ModalList, {
          title: getGDDNoteTitle(gdd, { deliveryPoint: this.deliveryPoint }),
          items: [
            {
              title: this.deliveryPoint.groupName,
              description: note,
              icon: 'ℹ️',
            },
          ],
          btnLabel: 'C’est noté !',
          btnTheme: 'go-green',
          btnCallback: () => this.$emit('onSubmit', gdd),
        });
      } else {
        this.$emit('onSubmit', gdd);
      }
    },
  },
};
</script>
