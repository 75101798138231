<template>
  <tunnel-body
    :is-ready="ready"
    size="wide">
    <potager-container class="max-w-6/12">
      <tunnel-box-message />
    </potager-container>

    <tunnel-box-slider
      :categories="enhancedCategories"
      :initial-slide="getInitialSlide"
      class="mb-8"
      @onSlideChange="onSlideChange"
      @onSubmit="onSubmit"
      @setSelectedRef="setSelectedRef" />

    <tunnel-box-reassurance v-if="!isSubscriptionActive" />
  </tunnel-body>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { FETCH_PRODUCT_SELECTORS_V2_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import GtmMixin from 'Mixins/GtmMixin';
import SubscriptionModalMixin from 'Mixins/SubscriptionModalMixin';
import TunnelSubscriptionMixin from 'Mixins/TunnelSubscriptionMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';
import TunnelBoxMessage from 'Components/tunnel/TunnelBoxMessage';
import TunnelBoxSlider from 'Components/tunnel/TunnelBoxSlider';
import TunnelBoxReassurance from 'Components/tunnel/TunnelBoxReassurance';

import { UPDATE_OAC_FLOW, UPDATE_OAC_RECOMMENDED_REF } from 'Stores/types/sessionMutationsTypes';

import {
  DEFAULT_PRODUCT_REFERENCE,
  DEFAULT_REGION_ID,
  PMEL,
  MEL,
  SPMEL,
} from 'Classes/Constants';

import MetaInfoService from 'Classes/services/MetaInfoService';

import PotagerContainer from 'UI/PotagerContainer';

export default {

  mixins: [
    GtmMixin,
    SubscriptionModalMixin,
    TunnelSubscriptionMixin,
  ],

  components: {
    PotagerContainer,
    TunnelBody,
    TunnelBoxMessage,
    TunnelBoxSlider,
    TunnelBoxReassurance,
  },

  data: () => ({
    ready: false,
    initRef: undefined,
    categories: [],
  }),

  computed: {
    ...mapGetters('session', {
      getSessionRegionId: 'getRegionId',
      getOacRecommendedRef: 'getOacRecommendedRef',
      isOacRecommendedRefNotEnough: 'isOacRecommendedRefNotEnough',
    }),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    getRegionId() {
      return this.getSessionRegionId || this.$route.params.regionId || DEFAULT_REGION_ID;
    },
    getRef() {
      return this.$route.params.ref === MEL ? PMEL : this.$route.params.ref;
    },
    getCatRef() {
      return this.categories
        .find((cat) => cat.variants
          .find((format) => format.product.productReference === (this.getRef || DEFAULT_PRODUCT_REFERENCE)))?.ref;
    },
    getCurrentCategory() {
      return this.categories
        .find((cat) => cat.ref === this.getCatRef);
    },
    getCurrentBox() {
      return this.getCurrentCategory
        ?.variants
        .find((format) => format.product.productReference === this.getCurrentCategory.selectedRef)
        ?.product;
    },
    getInitialSlide() {
      return this.initRef ? this.categories?.findIndex((cat) => cat.ref === this.initRef) : undefined;
    },
    enhancedCategories() {
      if (!this.categories) return [];

      const selectedRefOrder = [PMEL, SPMEL];
      let sortedCategoriesFilter = this.categories
        .filter((cat) => selectedRefOrder.includes(cat.variants[0].product.productReference || cat.selectedRef));
      const remainingCategories = this.categories
        .filter((cat) => !selectedRefOrder.includes(cat.variants[0].product.productReference || cat.selectedRef));

      sortedCategoriesFilter = sortedCategoriesFilter
        .sort((a, b) => {
          const aRef = a.variants[0].product.productReference || a.selectedRef;
          const bRef = b.variants[0].product.productReference || b.selectedRef;
          return selectedRefOrder.indexOf(aRef) - selectedRefOrder.indexOf(bRef);
        });

      let categories = [...sortedCategoriesFilter, ...remainingCategories];
      // on met la recommandation en premier dans le tableau
      categories = this.getOacRecommendedRef ? [categories
        .find((cat) => cat.variants.find((v) => v.product.productReference === this.getOacRecommendedRef) || cat.selectedRef === this.getOacRecommendedRef), ...categories
        .filter((cat) => !cat.variants.find((v) => v.product.productReference === this.getOacRecommendedRef) && cat.selectedRef !== this.getOacRecommendedRef)] : categories;

      // on met la reference dans l'url en premier dans le tableau
      categories = categories
        .sort((a) => (this.initRef === a.ref || this.initRef === a.selectedRef ? -1 : 0));

      return categories.filter((cat) => cat);
    },
  },

  watch: {
    getRegionId: {
      handler(val) {
        if (val) {
          // change route regionId param to match regionId
          // and fetch categories
          // tunnelBoxGuard is supposed to manage this,
          // BUT in webview, we don't have the regionId soon enough
          this.$router.replace({
            ...this.$route,
            params: {
              ...this.$route.params,
              regionId: val,
            },
          });

          this.fetchCategories();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('session', [
      UPDATE_OAC_FLOW,
      UPDATE_OAC_RECOMMENDED_REF,
    ]),
    fetchCategories() {
      this.$store.dispatch(`subscriptionBasket/${FETCH_PRODUCT_SELECTORS_V2_ACTION}`, {
        regionId: this.getRegionId,
      })
        .then(({ data }) => {
          this.categories = data
            .map((cat) => {
              let selectedRef;
              if (cat.variants.length === 1) {
                selectedRef = cat.variants[0].product.productReference;
              } else if (this.getOacRecommendedRef && cat.variants
                .find((v) => v.product.productReference === this.getOacRecommendedRef)) {
                selectedRef = this.getOacRecommendedRef;
              } else if (this.getRef && cat.variants
                .find((v) => v.product.productReference === this.getRef)) {
                selectedRef = this.getRef;
              } else {
                selectedRef = cat.variants[0].product.productReference;
              }

              return {
                ...cat,
                selectedRef,
              };
            });

          this.initRef = this.getRef || this.getCatRef;
          this.ready = true;
        });
    },
    setSelectedRef(ref, catRef) {
      this.categories = this.categories
        .map((cat) => {
          if (cat.ref === catRef) {
            return {
              ...cat,
              selectedRef: ref,
            };
          }
          return cat;
        });

      const newRoute = {
        name: this.$route.name,
        params: {},
      };

      if (ref) newRoute.params.ref = ref;
      if (this.$route.params.ref !== ref) this.$router.replace(newRoute);
    },
    findCatByProductRef(ref) {
      return this.categories
        .find((c) => c.variants.find((format) => format.product.productReference === ref));
    },
    onSlideChange(slideIndex) {
      const nextCat = this.enhancedCategories[slideIndex];

      if (nextCat.ref === this.getCurrentCategory.ref) return;

      const getRef = () => {
        if (nextCat.selectedRef) {
          return nextCat.selectedRef;
        }
        if (nextCat.variants.length === 1) {
          return nextCat.variants[0].product.productReference;
        }
        if (nextCat.variants
          .find((format) => format.product.productReference === this.initRef)) {
          return this.initRef;
        }

        return undefined;
      };
      const ref = getRef();
      const catRef = !ref && nextCat.variants.length > 1 ? nextCat.ref : null;
      this.setSelectedRef(getRef(), catRef);
    },
    onSubmit(box) {
      this.setSelectedRef(box.productReference);
      this.submitNewSubscription(box, this.findCatByProductRef(box.productReference)?.productOptions.length > 0);
    },
  },

  beforeUnmount() {
    this.$nextTick(() => {
      this.UPDATE_OAC_FLOW(null);
      this.UPDATE_OAC_RECOMMENDED_REF(null);
    });
  },

  head() {
    return this.getCurrentCategory ? MetaInfoService.generate({
      title: this.getCurrentCategory.name,
      description: this.getCurrentCategory.description,
    }) : {};
  },

};
</script>
