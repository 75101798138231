<template>
  <panel
    :back-route="backRoute"
    :is-loading="!isUserLoaded"
    :title="paymentMethod.title">
    <div class="panel-section">
      <template v-if="getSortedPaygreenCards?.length > 0">
        <transition-group
          class="panel-section__line"
          name="bluecard--transition"
          tag="div">
          <payment-card
            v-for="card in getSortedPaygreenCards"
            :key="card.id"
            :ref="`card_${card.id}`"
            :card="card"
            actions />
        </transition-group>
      </template>

      <p
        v-else
        class="panel-section__title">
        Vous n'avez pas encore ajouté de {{ paymentMethod?.label }}.
      </p>

      <panel-section-navigator
        :on-click="isRestaurantCard ? paymentMethod.actions.openAddModal : undefined"
        :to="!isRestaurantCard ? addCardRoute : undefined">
        Ajouter une {{ paymentMethod?.label }}
      </panel-section-navigator>
    </div>
  </panel>
</template>

<script>

import Panel from 'Components/panel/Panel';
import PaymentCard from 'Components/paymentCard/PaymentCard';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';

import { getPaymentMethodByName, getPaymentMethodByCard } from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodName, PaymentMethodType } from 'potagerlogic/dist/Enums/PaymentMethods';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {
  components: {
    PanelSectionNavigator,
    Panel,
    PaymentCard,
  },

  data() {
    return {
      PaymentMethodType,
    };
  },

  computed: {
    ...mapGetters('user', [
      'isUserLoaded',
      'getBankCards',
      'getPaygreenCards',
      'getSubscription',
      'getPaymentGateway',
    ]),
    name() {
      return this.$potagerRoute.params.name;
    },
    paymentMethod() {
      return getPaymentMethodByName(this.name);
    },
    getSortedPaygreenCards() {
      return this.paymentMethod.type === PaymentMethodType.RestaurantCard
        ? this.getPaygreenCards
          .filter((card) => getPaymentMethodByCard(card)?.name === this.name)
        : this.getBankCards;
    },
    isRestaurantCard() {
      return this.paymentMethod.type === PaymentMethodType.RestaurantCard;
    },
    backRoute() {
      const orderId = this.$potagerRoute.params.orderId || this.$potagerRoute.query.orderId;
      if (this.$potagerRoute.query.context === Context.Basket && this.$potagerRoute.query.skipHub) {
        return { name: 'basket_payment' };
      }
      if (this.$potagerRoute.query.context === Context.Order && this.$potagerRoute.query.skipHub && orderId) {
        return {
          name: 'mon-compte_orders_detail',
          params: { orderId }
        };
      }
      if (this.$potagerRoute.query.context === Context.Order && !this.$potagerRoute.query.skipHub) {
        return { name: 'mon-compte_orders' };
      }
      return { name: 'mon-compte_payment-methods' };
    },
    addCardRoute() {
      const isPaygreen = this.getPaymentGateway === PaymentMethodIntegration.Paygreen;
      if (isPaygreen) {
        const name = this.name === PaymentMethodName.Stripe ? PaymentMethodName.PaygreenBankCard : this.name;
        return {
          name: 'mon-compte_payment-methods_paygreen_add',
          params: { name },
        };
      } else {
        return { name: 'mon-compte_payment-methods_credit-card_add' };
      }
    },
  },

  mounted() {
    if (this.getSortedPaygreenCards?.length === 0 && this.paymentMethod.type === PaymentMethodType.RestaurantCard) {
      this.paymentMethod.actions.openAddModal(this);
    }
  },

  head() {
    return MetaInfosService.generate({
      title: `Mes ${this.paymentMethod?.label}`,
    });
  },
};
</script>
