<template>
  <div
    v-if="skeleton"
    class="card-locality w-1/2 skeleton" />

  <potager-tooltip
    v-else-if="box && (countries.length >= 1 || primaryTag || brandImage)"
    :disable="countries.length <= 1"
    :message="countriesTooltip"
    class="card-locality text-xs.5 font-semibold inline-flex items-center w-full"
    tag="div"
    theme="dark">
    <potager-badge
      v-if="primaryTag"
      :theme="primaryTag.color"
      class="mr-2"
      rounded
      size="small">
      {{ primaryTag.label }}
    </potager-badge>

    <template
      v-if="brandImage">
      <potager-picture
        :src="brandImage"
        class="card-locality__brand w-[25px] h-full mr-1" />

      <template v-if="brandName && !primaryTag">
        {{ brandName }}
      </template>
    </template>

    <div
      v-else
      class="gap-1 flex items-center w-full">
      <icon-flag-france-rounded
        v-if="origins[0]?.country?.toLowerCase() === 'france'"
        :class="[
          'card-locality__icon',
          'p-[5px] bg-white rounded-full inline-block text-[25px] w-[25px] min-w-[25px] h-[25px] leading-0',
        ]" />

      <span
        :title="mainOrigin"
        class="card-locality__main py-1 line-clamp-1">
        {{ mainOrigin }}
      </span>

      <span
        v-if="complementaryCountries"
        class="card-locality__complementary py-1 line-clamp-1"
        v-html="complementaryCountries" />
    </div>
  </potager-tooltip>
</template>

<script>
import PotagerTooltip from 'UI/PotagerTooltip';
import IconFlagFranceRounded from 'Icons/color/IconFlagFranceRounded';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerBadge from 'UI/PotagerBadge';
import { getBoxSuppliersArray } from 'potagerlogic/dist/Utils/Box/BoxSuppliers';

export default {
  name: 'CardLocality',
  components: {
    PotagerBadge,
    PotagerPicture,
    PotagerTooltip,
    IconFlagFranceRounded,
  },

  props: {
    box: {
      type: Object,
      required: false,
      default: undefined,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    origins() {
      if (!this.box) return [];
      return this.box.products
        .map((product) => {
          const origin = product.origin || product.supplier?.origin;
          const country = product.country || product.supplier?.country || origin;
          if (origin && origin
            .includes(',')) {
            const parts = origin
              .split(',')
              .map(part => part.trim());
            const [location, departmentNumber] = parts[0]
              .split('(')
              .map(part => part.trim());
            const department = location.trim();
            const departmentCode = departmentNumber ? departmentNumber.replace(')', '') : null;
            const region = parts.length > 2 ? parts[1] : null;
            const country = parts.length > 2 ? parts[2] : parts[1];
            return {
              country,
              department,
              departmentCode,
              region,
            };
          }
          return { country };
        })
        .sort((a, b) => {
          if (a.country?.toLowerCase() === 'france') return -1;
          if (b.country?.toLowerCase() === 'france') return 1;
          return 0;
        });
    },
    mainOrigin() {
      if (!this.origins[0]) return '';
      return `${this.origins[0].country} ${this.origins.length === 1 && this.origins[0].department ? ' - ' + this.origins[0].department : ''} ${this.origins.length === 1 && this.origins[0].departmentCode ? ' (' + this.origins[0].departmentCode + ')' : ''}`;
    },
    countries() {
      return this.origins
        .map((origin) => origin.country)
        .filter((country, index, self) => self.indexOf(country) === index);
    },
    suppliers() {
      if (!this.box) return [];
      return getBoxSuppliersArray(this.box);
    },
    brandImage() {
      return this.suppliers.find((supplier, index, self) =>
        supplier.brandImage && self.findIndex(s => s.brandImage === supplier.brandImage) === index
      )?.brandImage;
    },
    brandName() {
      return [...new Set(this.suppliers
        .filter(supplier => supplier.substituteName)
        .map(supplier => supplier.substituteName)
      )].join(', ');
    },
    complementaryCountries() {
      return this.countries.length > 1 ? `+&nbsp;${this.countries.length - 1} pays` : '';
    },
    countriesTooltip() {
      return this.countries
        .map((locality) => `<span class="whitespace-nowrap">${locality}</span>`)
        .join('<br />');
    },
    primaryTag() {
      const primaryTag = !this.small ? this.box?.tags?.find((tag) => tag.type === 'primary') : null;
      // const localTag = this.box?.isLocal ? {
      //   label: 'Local',
      //   color: 'british-racing',
      // } : null;
      const voucherTag = this.box?.isVoucher ? {
        label: 'Bons d\'achat',
        color: 'british-racing',
      } : null;

      return primaryTag || voucherTag;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-locality {
  $this: &;
  @apply h-[25px] mb-2;
}
</style>

<style lang="scss">
.card-locality {
  .card-v2--landscape & {
    @apply bp425:h-[21px];

    &__icon {
      @apply bp425:text-xs.5 bp425:p-1 bp425:leading-0;
    }

    &__brand {
      @apply bp425:w-[21px];
    }
  }
}
</style>
