<template>
  <div class="bg-lavender-blush">
    <potager-container class="text-center max-w-[700px] p-0">
      <potager-background
        background-repeat="no-repeat"
        background-size="700px"
        position="top"
        src="sponsorship-banner-page">
        <article class="w-full max-w-[310px] flex flex-col items-center justify-center mx-auto py-16">
          <p class="text-2xl.5 mb-5 font-extrabold text-byzantine">
            Bienvenue<br>chez Potager City&nbsp;!
          </p>

          <p class="text-2xl.5 font-lore-alternates font-bold">
            18€ offerts*
          </p>

          <p
            v-if="sponsorshipFirstName"
            class="text-lg mb-5">
            grâce à <b>{{ sponsorshipFirstName }}</b>
          </p>
          <p
            v-else
            class="bg-byzantine/20 rounded w-40 mb-4 mt-1 pulse">
            &nbsp;
          </p>

          <ul class="text-lg mb-5">
            <li>
              <b class="text-byzantine">
                10€
              </b> sur votre 1<sup>ère</sup> commande
            </li>

            <li>
              <b class="text-byzantine">
                5€
              </b> sur votre 2<sup>ème</sup> commande
            </li>

            <li>
              <b class="text-byzantine">
                3€
              </b> sur votre 3<sup>ème</sup> commande
            </li>
          </ul>

          <potager-button
            :is-disabled="!sponsorshipFirstName || !isSponsorshipCodeValid"
            :is-loading="isLoading"
            :tooltip="isSponsorshipCodeValid ? undefined : 'Ceci&nbsp;semble être&nbsp;votre propre code&nbsp;de&nbsp;parrainage'"
            class="mb-10"
            lowercase
            theme="byzantine"
            tooltip-position="left"
            @onClick="subscribe">
            J'en profite
          </potager-button>

          <p class="text-xxs.5 italic">
            *{{ getSponsorshipGodSonValue }} de remise valable sur la 1ère commande : minimum d'achat de
            {{ getSponsorshipMinGodSonValue }}. 5€ de remise valable 15 jours après la livraison de la 1ère commande :
            minimum d'achat de 15€. 3€ de remise valable 15 jours après la livraison de la 2ème commande : minimum
            d'achat de 13€.
          </p>
        </article>
      </potager-background>
    </potager-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolsMixin from 'Mixins/ToolsMixin';

import PotagerButton from 'UI/PotagerButton';
import PotagerBackground from 'UI/PotagerBackground';
import PotagerContainer from 'UI/PotagerContainer';

import ModalCouponPolicy from 'Modals/ModalCouponPolicy';

import { VALID_SPONSOR_CODE_ACTION, UPDATE_SPONSOR_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';
import { UPDATE_SPONSOR } from 'Stores/types/temporaryCustomerMutationsTypes';
import { SPONSORSHIP } from 'Classes/utils/CouponsUtils';

export default {

  data: () => ({
    sponsorshipFirstName: null,
  }),

  mixins: [
    ToolsMixin,
  ],

  components: {
    PotagerContainer,
    PotagerButton,
    PotagerBackground,
  },

  computed: {
    ...mapGetters('app', [
      'getSponsorshipMinGodSonValue',
      'getSponsorshipGodSonValue',
      'getSponsorshipPromotedText',
    ]),
    ...mapGetters('user', [
      'getMySponsorshipCode',
    ]),
    ...mapGetters('temporaryCustomer', [
      'getTemporaryCustomerId',
    ]),
    isLoading() {
      return this.$wait.is(UPDATE_SPONSOR);
    },
    sponsorshipCode() {
      return this.$potagerRoute.query.code;
    },
    isSponsorshipCodeValid() {
      return this.sponsorshipCode && this.sponsorshipCode !== this.getMySponsorshipCode;
    },
  },

  methods: {
    getValidSponsorshipCode(sponsorshipCode) {
      return this.$store.dispatch(`temporaryCustomer/${VALID_SPONSOR_CODE_ACTION}`, sponsorshipCode);
    },
    subscribe() {
      this.$router.push({ name: 'tunnel' });
    },
    openModal() {
      this.$modal.open(ModalCouponPolicy, {
        promotedCoupon: {
          couponType: SPONSORSHIP,
          ...this.getSponsorshipPromotedText,
        },
      });
    },
  },

  watch: {
    getTemporaryCustomerId: {
      handler() {
        if (!this.sponsorshipCode) return;

        this.getValidSponsorshipCode(this.sponsorshipCode)
          .then(({ firstname }) => {
            this.sponsorshipFirstName = firstname;
            if (this.isSponsorshipCodeValid) {
              this.$store.dispatch(`temporaryCustomer/${UPDATE_SPONSOR_ACTION}`, this.sponsorshipCode);
            }
          })
          .catch(() => {
            this.sponsorshipFirstName = null;
            this.$notify({
              type: 'error',
              title: 'Code de parrainage invalide',
              text: 'L\'url de parrainage est incorrecte.',
            });
          });
      },
      immediate: true,
    },
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Profitez de -10€ sur votre première commande.',
      description: 'Inscrivez-vous dès maintenant sur Potager City et profitez de -10€ sur votre 1ère commande, puis de -5€ et -3€ sur les suivantes !',
      image: 'https://www.potagercity.fr/images/meta_sponsorship.png',
    });
  },
};
</script>
