<template>
  <div class="panel panel--bianca border-l-0 tunnel-payment-aside mx-auto h-auto">
    <div class="panel__body">
      <transition-group
        name="tunnel-block--transition"
        tag="div">
        <potager-banner
          v-if="isAside"
          key="firstOrderBanner"
          class="mb-5"
          theme="beryl-green">
          👍 Votre {{ isFirstOrder ? '1ère' : '' }} commande sera <b>livrée le <span v-html="getDeliveryDate" />.</b> Pas
          d’inquiétude,
          vous pouvez modifier et stopper
          votre abonnement quand vous voulez.
        </potager-banner>

        <tunnel-receipt-detail key="receiptDetail" />

        <div
          v-if="hasProductBlock"
          key="productBlock"
          class="panel-section mx-0">
          <p class="panel-section__title">
            Votre abonnement 🍎🥕
          </p>

          <potager-receipt-details
            v-if="getSubscriptionDetails?.length"
            :lines="getSubscriptionDetails" />
          <div
            v-else
            class="animate-pulse w-full h-8 bg-white-rock rounded-lg mb-4 last:mb-0" />
        </div>

        <div
          v-if="hasDeliveryPointBlock"
          key="deliveryPointBlock"
          class="panel-section mx-0">
          <p class="panel-section__title">
            {{ getDeliveryPoint?.isHomeDelivery ? 'Votre livraison 🚜' : 'Votre retrait 🚜' }}
          </p>

          <template v-if="getDeliveryPoint">
            <potager-receipt-details
              :lines="getShippingDetail"
              class="mb-4" />

            <div class="panel-section__separator" />

            <p class="panel-section__p text-xs.5">
              <b> {{ deliveryName }} </b>
              <br>
              {{ deliveryAddress }}
            </p>

            <p class="panel-section__p text-xs.5">
              <b> {{ getDeliveryPoint.isHomeDelivery ? 'Livraison prévue' : 'Retrait de commande' }} </b>
              <br>
              {{ getDisponibility }}
            </p>
          </template>

          <template v-else>
            <div
              v-for="i in 2"
              :key="i"
              class="animate-pulse w-full h-8 bg-white-rock rounded-lg mb-4 last:mb-0" />
          </template>
        </div>
      </transition-group>

      <div class="flex justify-center mt-6">
        <potager-button
          v-if="!isAside"
          :to="getNextStepRoute()"
          class="potager-button--tunnel mt-2 mb-8"
          theme="go-green">
          Continuer
        </potager-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import GtmMixin from 'Mixins/GtmMixin';

import { getOrderPrice } from 'potagerlogic/dist/Utils/Order/OrderPrices';
import { formatPrice } from 'potagerlogic/dist/Formatting';
import { getGddFormattedDay } from 'potagerlogic/dist/Utils/DeliveryPoint/GroupDeliveryDay';
import { tmsToFormat } from 'potagerlogic/dist/Utils/Dates/DateFormat';

import PotagerButton from 'UI/PotagerButton';
import PotagerBanner from 'UI/PotagerBanner';
import PotagerReceiptDetails from 'UI/PotagerReceiptDetails';

import TunnelReceiptDetail from 'Pages/tunnel/07.summary/components/TunnelReceiptDetail';
import { Context } from 'potagerlogic/dist/Enums/Context';

export default {

  props: {
    isAside: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    GtmMixin,
  ],

  components: {
    TunnelReceiptDetail,
    PotagerBanner,
    PotagerButton,
    PotagerReceiptDetails,
  },

  computed: {
    ...mapGetters('user',
      {
        getOrders: 'getOrders',
        getTotalPaidOrders: 'getTotalPaidOrders',
        firstName: 'getFirstName',
        lastName: 'getLastName',
      }),
    ...mapGetters('subscriptionBasket', [
      'getAddress',
      'getDeliveryPoint',
      'getGroupDeliveryDay',
      'getSubscriptionBasket',
      'getCustomerSubscriptionProduct',
      'getPriceCategory',
      'getPriceSubscription',
      'getCoupons',
    ]),
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    isFirstOrder() {
      return !this.getOrders.length && !this.getTotalPaidOrders;
    },
    hasProductBlock() {
      return this.getCustomerSubscriptionProduct?.id;
    },
    hasDeliveryPointBlock() {
      return this.getDeliveryPoint?.id && this.hasProductBlock;
    },
    getDisponibility() {
      const { isHomeDelivery } = this.getDeliveryPoint;
      const day = tmsToFormat(this.getGroupDeliveryDay.currentWeekTheoricalTimeSlot.date, 'iiii');
      const from = tmsToFormat(this.getGroupDeliveryDay.currentWeekTheoricalTimeSlot.startHour, 'HH\'h\'mm');
      const to = tmsToFormat(this.getGroupDeliveryDay.currentWeekTheoricalTimeSlot.endHour, 'HH\'h\'mm');

      return isHomeDelivery ? `Chaque ${day}, entre ${from} et ${to}` : `Chaque ${day} dès ${from} jusqu'à ${to}`;
    },
    getDeliveryDate() {
      return getGddFormattedDay(this.getGroupDeliveryDay, { showHours: true });
    },
    getEnterpriseDiscount() {
      return getOrderPrice({
        ...this.getSubscriptionBasket,
        isSubscriptionOrder: true,
        containsSubscriptionBox: true,
      })?.enterpriseDiscount;
    },
    getBoxPrice() {
      return this.getCustomerSubscriptionProduct?.price?.subscription;
    },
    // Formattage du prix de la box
    boxPrice() {
      return [{
        label: `x1 Un abonnement hebdomadaire : ${this.getCustomerSubscriptionProduct.name}`,
        value: `${formatPrice(this.getBoxPrice)}`,
      }];
    },
    // Formattage de la réduction entreprise
    enterpriseDiscount() {
      return parseFloat(this.getEnterpriseDiscount) ? [{
        label: 'Remise entreprise',
        value: `-${formatPrice(this.getEnterpriseDiscount)}`,
      }] : [];
    },
    getSubscriptionDetails() {
      return [
        ...this.boxPrice,
        ...this.enterpriseDiscount,
      ];
    },
    getShippingDetail() {
      return [{
        label: this.getDeliveryPoint.isHomeDelivery ? 'Livraison à domicile' : 'Livraison en point relais',
        value: !parseFloat(this.getPriceSubscription.shippingCost) ? 'Offerte' : `${formatPrice(this.getPriceSubscription.shippingCost)}`,
        type: !parseFloat(this.getPriceSubscription.shippingCost) ? 'success' : undefined,
      }];
    },
    isHomeDelivery() {
      return this.getDeliveryPoint.isHomeDelivery;
    },
    deliveryName() {
      const {
        isHomeDelivery,
        groupName
      } = this.getDeliveryPoint;
      return isHomeDelivery ? `${this.firstName} ${this.lastName}` : groupName;
    },
    deliveryAddress() {
      const {
        isHomeDelivery,
        groupDeliveryAddress
      } = this.getDeliveryPoint;

      if (isHomeDelivery) {
        if (!this.getAddress) {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la récupération de votre adresse de livraison.',
          });

          return 'Adresse de livraison non disponible';
        }

        const {
          streetName,
          postalCode,
          city
        } = this.getAddress;
        return `${streetName} ${postalCode} ${city}`;
      }

      return groupDeliveryAddress;
    },
  },

  methods: {
    isVisible() {
      return !this.$mq.bp1024;
    },
  },
};
</script>

<style lang="scss">
@include define-transition(both, tunnel-block--transition) {
  transition: transform .3s, opacity .3s;
}

@include define-transition(both, tunnel-block--transition) {
  transform: translate(1rem, 0);
  opacity: 0;
}

.tunnel-payment-aside {
  @include bp1024() {
    width: 100%;
  }
}
</style>
